// src/components/NewItem.js
import React from 'react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'; // Import the CSS for tooltips

const NewItem = ({
  name,
  link,
  info,
  playHoverSound,
  playClickSound,
}) => {
  // Function to handle info icon click (optional, as tooltip is shown on hover)
  const handleInfoClick = () => {
    playClickSound();
  };

  return (
    <div className="flex items-center space-x-2">
      {/* Project Name */}
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg font-medium hover:text-lm-orange transition-colors cursor-pointer"
          onMouseEnter={playHoverSound}
          onClick={playClickSound}
        >
          {name}
        </a>
      ) : (
        <span
          className="text-lg font-medium hover:text-lm-orange transition-colors cursor-pointer"
          onMouseEnter={playHoverSound}
          onClick={playClickSound}
        >
          {name}
        </span>
      )}

      {/* "NEW" Label */}
      <span className="text-sm font-semibold animate-color-cycle">
        LIVE
      </span>

      {/* Info Icon with Tooltip */}
      {info && (
        <>
          <button
            className="focus:outline-none mb-1"
            onMouseEnter={playHoverSound}
            onClick={handleInfoClick}
            aria-label={`More information about ${name}`}
            data-tooltip-id={`tooltip-${name}`}
            data-tooltip-place="top"
          >
            <FaInfoCircle className="text-red-500 hover:text-green-700" />
          </button>

          {/* Tooltip */}
        <Tooltip
            id={`tooltip-${name}`}
            className="max-w-xs bg-gray-800 text-white pointer-events-auto" // Solid background and white text
            interactive={true} // Make tooltip interactive
            delayHide={300} // Adjust delay to prevent premature hiding
            zIndex={50} // Ensure tooltip is on top
            clickable
            render={() => (
            <div>
                {name === "D.P.P" ? (
                <>
                    {info}
                    <div className="mt-2">
                    <a
                        href="https://x.com/ClutchMarkets/status/1860096064428163495"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-lm-yellow underline"
                    >
                        Read more...
                    </a>
                    </div>
                </>
                ) : (
                info
                )}
            </div>
            )}
        />
        </>
      )}
    </div>
  );
};

NewItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string, // Optional
  info: PropTypes.node, // Optional
  playHoverSound: PropTypes.func.isRequired,
  playClickSound: PropTypes.func.isRequired,
};

export default NewItem;
